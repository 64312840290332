import React from "react";
import * as moment from "moment";

const ExistingDocuments = (props) => {
  if (props.documents) {
    return (
      <div className="card">
        <div className="card-body">
          Existing Documents:
          <br />
          {props.documents.map((shipmentDocument) => (
            <>
              <span id={shipmentDocument.id}>
                <a
                  href={"" + shipmentDocument.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  {shipmentDocument.name}
                </a>
                - {moment(shipmentDocument.createdAt).format("MMM Do YYYY")}
              </span>
              <br />
            </>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ExistingDocuments;
