import ShippersEdgeAPIs from "../API";

export const GetShipments = async (portalType, locationcodes) => {
  if (locationcodes.includes(",")) {
    // Multiple location codes requests
    let locationCodesArray = locationcodes.split(",");

    const asyncLoopApiRequests = async () => {
      let allLocationsCombined = [];

      for (const locationcode of locationCodesArray) {
        const asnycResult = await ShippersEdgeAPIs.GetShipmentsByLocationCode.GetAllShipmentsByLocationCode(portalType,
          locationcode
        );
        allLocationsCombined = [...asnycResult.data, ...allLocationsCombined];
      }
      // Remove nulls from the shipments
      let allLocationsCombinedNoNulls = JSON.parse(
        JSON.stringify(allLocationsCombined).replace(/\:null/gi, ':""') // eslint-disable-line
      );
      return allLocationsCombinedNoNulls;
    };

    return asyncLoopApiRequests();
  } else {
    // Single location code request
    const shipments = await ShippersEdgeAPIs.GetShipmentsByLocationCode.GetAllShipmentsByLocationCode(portalType,
      locationcodes
    );
    // console.log("GetShipments.js", shipments.data);
    if (shipments.data) {
      // Remove nulls from the shipments
      const shipmentsNoNulls = JSON.parse(
        JSON.stringify(shipments.data).replace(/\:null/gi, ':""') // eslint-disable-line
      );
      // const formattedShipments = ShipmentFormatter(removeNulls);
      return shipmentsNoNulls;
    } else {
      return [];
    }
  }
};
